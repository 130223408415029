.current-ppl__grid--men .grid__tile {
    background-color: color($vitalien, dark);
}

.current-ppl__grid--women .grid__tile {
    background-color: color($vitalien, default);
}

.current-ppl__content {
    @include position-center;
    text-align: center;
}

.current-ppl__header {
    text-transform: uppercase;
    color: color($vitalien, yellow);
    @include font-roboto-bold;
    font-size: setting($font-size, default);
    span {
        @include font-roboto-light;
        font-size: setting($font-size, l);
        line-height: setting($font-size, s);
    }
}

.current-ppl__men {
    @include font-roboto-light;
    font-size: setting($font-size, s);
    span {
        font-size: setting($font-size, xxl);
        line-height: setting($font-size, s);
    }
}
