.kurse__events {
    font-size: setting($font-size, s);
}

.kurse__event {
    padding-bottom: 1.5vh;
    &:first-child {
        padding-top: 0;
    }
}

.kurse--no {
    @include position-center;
    text-transform: uppercase;
    text-align: center;
    color: color($vitalien, yellow);
    font-size: setting($font-size, l);
    line-height: setting($font-size, xs);
    @include font-roboto-bold;
}

.kurse__event__title {
    color: $white;
    @include font-roboto-bold;
}

.kurse__event__date {
    color: color($vitalien, yellow);
    font-size: setting($font-size, xxs);
}

.error {
    display: inline-block;
    background-color: #fff;
    font-size: setting($font-size, xxs);
    color: color($red);
    @include font-roboto-bold;
    padding: 0.5vh 1vh;
    margin-bottom: 0.5vh;
    border-radius: 5px;
}