.vitalien-news h1 {
    color: color($vitalien, darker);
}

.vitalien-news h2 {
    color: color($vitalien, moredark);
    font-size: setting($font-size, s);
}

.vitalien-news__events {
    color: color($vitalien, moredark);
}

.vitalien-news__event {
    padding-top: 0;
    padding-bottom: 1.5vh;
    border: none;
    p {
        margin-bottom: 2vh;
    }
}

.vitalien-news__event__title {
    @include font-roboto-bold;
}

.vitalien-news__event__date {
    margin-top: 1vh;
    font-size: setting($font-size, xxs);
}
