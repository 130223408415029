@mixin font-roboto-light {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
}

@mixin font-roboto {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
}

@mixin font-roboto-bold {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
}

@mixin font-roboto-black {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
}

$font-size: (
        base: 1.6vw,
        xxs: .65em,
        xs: .8em,
        s: .9em,
        default: 1em,
        m: 1.1em,
        l: 2em,
        xl: 3em,
        xxl: 4em,
);
