.current-time__grid .grid__tile {
    background-color: color($vitalien, darker);
}

.current-time__content {
    @include position-center;
    text-align: center;
}

.current-time__time {
    @include font-roboto-light;
    font-size: setting($font-size, l);
    letter-spacing: .05em;
    line-height: 1;
}

.current-time__date {
    @include font-roboto-bold;
    color: color($vitalien, yellow);
    font-size: setting($font-size, m);
}
