.news h1 {
    color: #c8bc1e;
}

.news h2 {
    color: color($vitalien, yellow);
    font-size: setting($font-size, s);
}

.news__events {
    color: color($vitalien, moredark);
}

.news__event {
    padding-top: 0;
    padding-bottom: 1.5vh;
    border: none;
}

.news__event__title {
    @include font-roboto-bold;
}

.news__event__date {
    margin-top: 0.2vh;
    font-size: setting($font-size, xxs);
    line-height: setting($font-size, xs);
    color: color($vitalien, lighter);
}

.news__event__text {
    margin-top: 1.5vh;
    font-size: setting($font-size, xxs);
    line-height: 1.2em;
    color: $white;
}
