.dashboard {
    @include font-roboto;

    background-color: color($vitalien, lightest);
    bottom: setting($grid, cell-spacing)/2;
    color: $white;
    font-size: setting($font-size, base);
    left: setting($grid, cell-spacing)/2;
    overflow: hidden;
    position: fixed;
    right: setting($grid, cell-spacing)/2;
    top: setting($grid, cell-spacing)/2;
}
