@mixin antialiasing($value: subpixel) {
    @if $value == subpixel {
        -webkit-font-smoothing: subpixel-antialiased;
    }
    @if $value == pixel {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
}

@mixin background-logo($url){
    height: 100%;
    background-position: center;
    background-size: #{(100/setting($grid, cols) - 9)}vw;
    background-repeat: no-repeat;
    //opacity: .2;
    background-image: url($url);
}

@mixin background-icon($url){
    height: 100%;
    background-position: center;
    background-size: #{(100/setting($grid, cols) - 15)}vw;
    background-repeat: no-repeat;
    opacity: .2;
    background-image: url($url);
}

@mixin background-pattern($url){
    height: 100%;
    background-position: center;
    background-size: #{(100/setting($grid, cols) - 15)}vw;
    background-repeat: repeat;
    opacity: .2;
    background-image: url($url);
}

@mixin icon($url){
    background-image: url($url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1em;
    content: '';
    display: inline-block;
    height: 1em;
    vertical-align: middle;
    width: 1em;
}

@mixin clearfix {
    zoom: 1;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin tile-color($color){

    background-color: $color;

    &:after {
        @include gradient-vertical(rgba($color,0), rgba($color, 1));
    }
}

@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin gradient-vertical($start, $stop, $from: 0%, $to: 100%) {
    background: -webkit-linear-gradient(top, $start $from,$stop $to);
    background: linear-gradient(to bottom, $start $from, $stop $to);
}

@mixin position-center-horizontal($position: absolute) {
    left: 50%;
    position: $position;
    transform: translateX(-50%);
}


@mixin position-center-vertical($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}


@mixin position-center($position: absolute) {
    left: 50%;
    position: $position;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@mixin mod($name){
    &--#{$name} {
        @content ;
    }
}