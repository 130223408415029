$vitalien: (
        lightest: #ebf3a5,
        lighter: #cdd966,
        light: #99a727,
        default: #8b9725,
        dark: #7d881d,
        darker: #667200,
        darkest: #5c6701,
        moredark: #2c300b,
        yellow: #ffed00,
);
$red: (
        lightest: #fcebec,
        lighter: #e69a9c,
        light: #f76b6e,
        default: #e83134,
        dark: #a32324,
        darker: #63010e,
        darkest: #3d0109,
);
$orange: (
        lightest: #ffe5ca,
        lighter: #f5b180,
        light: #e77636,
        default: #e14a0c,
        dark: #711f03,
        darker: #581902,
        darkest: #391102,
);
$yellow: (
        lightest: #f7e8d4,
        lighter: #f7d1a1,
        light: #f6d169,
        default: #fbbd1a,
        dark: #b06f1c,
        darker: #734d00,
        darkest: #422c00,
);
$blue: (
    lightest: #e5f4f8,
    lighter: #a7dceb,
    light: #73c7de,
    default: #13869e,
    dark: #11727f,
    darker: #202f53,
    darkest: #262835,
);
$green: (
    lightest: #cce9e5,
    lighter: #bfe3de,
    light: #a9d6d0,
    default: #5ebbae,
    dark: #40988c,
    darker: #287368,
    darkest: #13463f,
);
$gray: (
        lightest: #ededf5,
        lighter: #cdcdda,
        light: #adadbb,
        default: #ababbb,
        dark: #53575e,
        darker: #23272f,
        darkest: #1a1e25,
);
$black: #000;
$white: #fff;
