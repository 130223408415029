.rain-forecast__item {
    @include position-center;
    text-align: center;
    color: color($vitalien, yellow);
    .rain-forecast__symbol {
        width: 75%;
    }
    .rain-forecast__desc {
        margin-top: 1vh;
        @include font-roboto-bold;
        text-transform: uppercase;
    }
    .rain-forecast__temp {
        margin-top: 1vh;
        @include font-roboto-light;
        letter-spacing: -0.1em;
        font-size: setting($font-size, xl);
        line-height: setting($font-size, s);
    }
}
